<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="中山大学" @moTitltFn="navPanel"></MoTitle>
    <div class="zhongSan">
      <!-- 头部 -->
      <div class="moTitle center">
      </div>
      <!-- 高校简介 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">高校简介</h2>
        <span class="spanDesc"></span>
        <div style="margin:30px 0 40px">
            <p style="line-height: 1.5rem;margin-bottom:10px">
                中山大学由孙中山先生创办，有着一百多年办学传统。作为中国教育部直属高校，通过部省共建，中山大学已经成为一所国内一流、国际知名的现代综合性大学。现由广州校区、珠海校区、深圳校区三个校区、五个校园及十家附属医院组成。中山大学正在向世界一流大学迈进，努力成为全球学术重镇。
            </p>
            <p style="line-height: 1.5rem;">
                中山大学具有人文社科和理医工多学科厚实基础，不断追求学术创新，以国际视野开放办学，现已形成了“综合性、研究型、开放式”的特色。学校坚持中国特色社会主义办学方向，坚持立德树人根本，以“面向世界科技前沿、面向经济主战场、面向国家重大需求、面向人民生命健康”为基本导向；树立了“三校区五校园”错位发展、合力支撑的发展思路。学校正在努力推进由外延式发展向内涵式发展转变，由常规发展向主动发展转变，由文理医优势向文理医工各具特色、融合发展转变。现在，中山大学正站在新的起点上，为稳居国内高校第一方阵，建设世界一流大学的目标努力奋斗！
            </p>
        </div>
      </div>
      <!-- 建设挑战 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">建设挑战</h2>
        <span class="spanDesc"></span>
        <div style="margin:10px 0 40px;width:100%">
              <MoListDesc :descList="constructionDesc" imgWidth="50px" :showBgColor="true" ListHeight="4.375rem"></MoListDesc>
          </div>
      </div>
      <!-- 总体规划 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">总体规划</h2>
        <span class="spanDesc"></span>
        <p style="line-height: 1.5rem;margin:30px 0 10px">017年，易普行开始参与中山大学人力资源信息化的规划和建设，项目建设采取整体规划、分期建设、逐步实施、持续优化的实施模式。以数据为核心，各个模块设立数据入口，在建设由数据标准体系及其支撑环境所构成的全域教职工数据库基础上，推动整个人事信息化服务体系的完善与发展。这种模式激发了教职工“人人有责”的强大的驱动力，真正实现了“用起来”。</p>
        <div style="margin-bottom:40px;width:100%">
              <MoListDesc :descList="planDesc" imgWidth="50px" :showBgColor="true" ListHeight="6.125rem"></MoListDesc>
          </div>
      </div>
      <!-- 建设成果 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">建设成果</h2>
        <span class="spanDesc"></span>
        <p style="line-height: 1.5rem;margin:30px 0;text-align:center">经过四期规划与建设，实现并建立中山大学人力资源展现中心、数据中心、业务中心、支撑中心</p>
        <div style="margin-bottom:30px">
               <MoListDesc :descList="step" imgWidth="50px"></MoListDesc>
        </div>
      </div>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoListDesc from '@/components/moListDesc'
export default {
  name: 'moZhongSan',
  components: {
    MoTitle,
    MoListDesc
  },
  data () {
    return {
      isScroll: false,
      constructionDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/one.png'),
          desc: [
            { p: '教职工众多且分散' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/two.png'),
          desc: [
            { p: '人事业务复杂且事务繁多' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/three.png'),
          desc: [
            { p: '人事制度、流程多变' }
          ]
        }
      ],
      planDesc: [
        {
          src: require('@/assets/TypicalCase/zhongsan/data.png'),
          title: '数据层面',
          desc: [
            { p: '实行数据责任制，数据管理人人有责' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/yewu.png'),
          title: '业务层面',
          desc: [
            { p: '根据学校的需求定制开发业务模块，业务模式高度匹配；' }
          ]
        },
        {
          src: require('@/assets/TypicalCase/zhongsan/serve.png'),
          title: '服务层面',
          desc: [
            { p: '为教职工提供便捷的自助服务，服务获取随时随地，提升学校整体人力资源管理与服务能力。' }
          ]
        }
      ],
      step: [
        {
          title: '人力资源展现中心',
          desc: [
            { p: '通过官网、微信公众号、网上服务大厅等渠道展现中山大学人力资源信息化应用成效，并充分利用现有入口资源，按不同用户习惯组织服务，泛在化获取服务。' }
          ],
          src: require('@/assets/solute/SmartCampus/smartOne.png')
        },
        {
          title: '人力资源数据中心',
          desc: [
            { p: '建立全面、统一的教职工信息库，通过科学的数据采集和数据管理机制，多渠道采集、整合、维护和存储与教职工相关的人事、教学、科研等360度全方位数据信息。' }
          ],
          src: require('@/assets/solute/SmartCampus/smartTwo.png')
        },
        {
          title: '人力资源业务中心',
          desc: [
            { p: '围绕教职工全生命周期人事业务进行管理与服务，实现教职工入校、在校、离校全生命周期业务管理。并以业务为抓手，进一步提升数据质量。' }
          ],
          src: require('@/assets/solute/SmartCampus/smartThree.png')
        },
        {
          title: '人力资源支撑中心',
          desc: [
            { p: '建立项目管理机制，规范工作及业务标准，并建设完备的角色权限体系。' }
          ],
          src: require('@/assets/solute/SmartCampus/smartFour.png')
        }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/typicalCase/zhongSan')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/TypicalCase/zhongsan/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.zhongSan{
  padding-top: 2.75rem;
}
</style>
